/**
 * Prev and next post nav
 *
 * @author David Faber
 * @author Ahmed Al-Yasiri
 * @copyright (c) Peritus Webdesign GmbH
 **/
// NPM
import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';
import classnames from 'classnames';

// Peritus
import {CreateLocalLink, Translate} from 'utilities';

// Styles
import * as styles from './post-nav.module.scss';
import {block} from '../Block/block.module.scss';

// Assets
import ArrowIcon from 'images/arrow.inline.svg';

const PostNav = ({previous, next}) => (<>
        {(previous || next) && <div className={classnames({[styles.nav]: true, [block]: true})}>
            {previous && <Link to={CreateLocalLink(previous)} className={styles.prev}>
                <ArrowIcon className={styles.arrow}/>
                {Translate('previous')}
            </Link>}
            {next && <Link to={CreateLocalLink(next)} className={styles.next}>
                {Translate('next')}
                <ArrowIcon className={styles.arrow}/>
            </Link>}
        </div>}
</>);

PostNav.propTypes = {
    previous: PropTypes.string,
    next: PropTypes.string,
}

PostNav.defaultProps = {
    previous: '',
    next: '',
};

export default PostNav;
