/**
 * Our text-block
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as styles from './text.module.scss';
import {ButtonLink, ButtonLinkProps} from 'components/Button';
import Block from 'components/Block';

const Text = ({
    title,
    subtitle,
    titleAlign,
    text,
    textAlign,
    columns,
    buttonAlign,
    hasButton,
    buttons,
    inLayout,
    className,
    ...props
}) => {
    columns = parseInt(columns);

    // Title
    const titleClasses = classNames({
        [`text-${titleAlign}`]: true,
    });

    subtitle = subtitle ? <small className={styles.subline} dangerouslySetInnerHTML={{__html: subtitle}}/> : '';
    title = title
        ? <h2 className={titleClasses}>
            <span dangerouslySetInnerHTML={{__html: title}}/>
            {subtitle}
        </h2>
        : '';

    // Text
    const textClasses = classNames({
        [styles[`col${columns}`]]: 1 < columns,
        [`text-${textAlign}`]: 1 === columns,
    });
    text = text ? <div className={textClasses} dangerouslySetInnerHTML={{__html: text}}/> : '';

    // Buttons
    const buttonClasses = classNames({
        [styles.buttons]: true,
        [`text-${buttonAlign}`]: true,
    });
    buttons = hasButton && buttons ? <div className={buttonClasses}>
        {buttons.map((button, key) => (<ButtonLink key={key} link={button} extraClasses={styles.button}/>))}
    </div> : '';

    if (title || text || buttons) {
        const blockClasses = classNames({
            [styles.textBlock]: true,
            [className]: true,
        });

        return (<Block className={blockClasses} {...props}>
            {title}
            {text}
            {buttons}
        </Block>);
    }

    return '';
};

Text.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    titleAlign: PropTypes.string,
    text: PropTypes.string,
    textAlign: PropTypes.string,
    columns: PropTypes.string,
    hasButton: PropTypes.bool,
    buttons: PropTypes.arrayOf(PropTypes.shape(ButtonLinkProps).isRequired),
    inLayout: PropTypes.bool,
    className: PropTypes.string,
};

Text.defaultProps = {
    title: '',
    subtitle: '',
    titleAlign: 'left',
    text: '',
    textAlign: 'left',
    columns: 'col-1',
    hasButton: false,
    buttons: [],
    inLayout: false,
    className: '',
};

export default Text;
