/**
 * Our blog-post-page
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
// NPM
import React from 'react';
import PropTypes from 'prop-types';

// Peritus
import Seo, {SeoShape} from 'components/Seo';
import Header from 'components/Header';
import Footer from 'components/Footer';
import {BlogHero, BlogHeader, PrevNextPost, SocialShares} from 'components/BlogPost';
import {ResponsiveImageProps} from 'components/ResponsiveImage';
import {LangShape} from 'components/Languages';
import {Translate} from 'utilities';

const BlogPost = ({data, Blocks}) => {
    const {
        title,
        uri,
        date,
        dateString,
        seo,
        language,
        translations,
        categories,
        acf: {
            heroImage,
            pagebuilder: {
                layouts,
            },
        },
        previous,
        next,
    } = data;


    return (<>
        <Seo seoData={seo} translations={translations} language={language} url={uri}/>
        <Header siteTitle={seo.title} translations={translations} language={language}/>
        <main>
            <BlogHero categories={categories} thumbnailId={heroImage}/>
            <BlogHeader date={date} categories={categories} title={title} dateString={dateString}/>
            {layouts && layouts.map((block, index) => (<Blocks key={index} block={block}/>))}
            <SocialShares title={`${Translate('shareNow')}:`}/>
            <PrevNextPost previous={previous ? previous.uri : ''} next={next ? next.uri : ''}/>
        </main>
        <Footer/>
    </>);
};

BlogPost.propTypes = {
    data: PropTypes.shape({
        title: PropTypes.string.isRequired,
        uri: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        dateString: PropTypes.string.isRequired,
        previous: PropTypes.shape({
            uri: PropTypes.string.isRequired,
        }),
        next: PropTypes.shape({
            uri: PropTypes.string.isRequired,
        }),
        seo: SeoShape.isRequired,
        ...LangShape,
        acf: PropTypes.shape({
            heroImage: PropTypes.shape({...ResponsiveImageProps}).isRequired,
            pagebuilder: PropTypes.shape({
                layouts: PropTypes.array.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
};

export default BlogPost;
