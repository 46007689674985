// extracted by mini-css-extract-plugin
export var caption = "image-module--caption--b2fe0";
export var figure = "image-module--figure--19311";
export var image = "image-module--image--3975f";
export var left = "image-module--left--ab110";
export var link = "image-module--link--973d1";
export var mobileHidden = "image-module--mobile-hidden--a2473";
export var overlay = "image-module--overlay--8b299";
export var overlaySvg = "image-module--overlay-svg--f978f";
export var overlayText = "image-module--overlay-text--9e5e8";
export var right = "image-module--right--a1f2f";
export var size2 = "image-module--size-2--fa2d9";
export var size4 = "image-module--size-4--a1eb3";