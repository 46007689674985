/**
 * Our Social-Share-Section
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
import React, {useContext, useEffect, useState} from 'react';
import SocialIcon from '../SocialIcon';
import * as styles from './social-shares.module.scss';
import PropTypes from 'prop-types';
import {Globals} from 'utilities';

const getNetworks = ({link, pageTitle}) => [
        {name: 'mail', href: `mailto:?Subject=${encodeURIComponent(pageTitle)}&body=${link}`},
        {name: 'whatsapp', href: `whatsapp://send?text=${pageTitle}:${link}`},
        {name: 'Facebook', href: `http://www.facebook.com/share.php?title=${pageTitle}&u=${link}`},
        {name: 'LinkedIn', href: `http://www.linkedin.com/shareArticle?url=${link}&title=${pageTitle}`},
];

const SocialShares = ({title}) => {
    const {translations: {shareOn}} = useContext(Globals);
    const [pageData, setPageData] = useState({link: '', pageTitle: ''});

    useEffect(() => {
        setPageData({
            link: window.location.href,
            pageTitle: encodeURIComponent(document.title),
        })
    }, []);

    return (<div className={styles.socialShares}>
        <span className={styles.socialSharesTitle}>{title}</span>
        {getNetworks(pageData).map((network, key) => (
            <SocialIcon key={key}
                network={network.name.toLowerCase()}
                link={network.href}
                title={shareOn.replace('%s', network.name)}
            />
        ))}
    </div>);
};

SocialShares.propTypes = {
    title: PropTypes.string.isRequired,
};

export default SocialShares;
