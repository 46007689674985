/**
 * Our button-component
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */

import React from 'react';

// Available Blocks
import Text from '../../blocks/Text';
import Image from '../../blocks/Image';
import Video from '../../blocks/Video';
import PropTypes from 'prop-types';


const SimpleLayout = ({blocks, layoutClasses, blockClasses, rowIndex}) => {
    const availableBlocks = {
        'Text': Text,
        'Image': Image,
        'Video': Video,
    };

    return (
        <div className={layoutClasses} data-row={rowIndex}>
            {
                blocks.map((block, index) => (
                    React.createElement(
                        availableBlocks[block.__typename.replace(/.*_([a-z]*)$/gi, '$1')],
                        {
                            key: index,
                            rowIndex: `${rowIndex}-${index}`,
                            inLayout: true,
                            className: blockClasses,
                            ...block,
                        },
                    )
                ))
            }
        </div>
    );
};

SimpleLayout.propTypes = {
    blocks: PropTypes.array.isRequired,
    layoutClasses: PropTypes.string,
    blockClasses: PropTypes.string,
    rowIndex: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
};

SimpleLayout.defaultProps = {
    layoutClasses: '',
    blockClasses: '',
    rowIndex: '',
};

export default SimpleLayout;
