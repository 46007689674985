/**
 * Our columns-block
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
// NPM
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Peritus
import SimpleLayout from 'components/SimpleLayout';
import Block from 'components/Block';

// Styles
import * as styles from './columns.module.scss';
import {wide as wideStyle, layout} from 'components/Block/block.module.scss';

const Columns = ({col1, col2, col3, colCount, colWidth, centerVertically, invert, wide, ...props}) => {

    colCount = parseInt(colCount);

    const containerClassNames = classNames({
        [styles.columns]: true,
        [styles.inverted]: invert,
        [styles.verticallyCentered]: centerVertically,
        [styles[colWidth]]: 'half' !== colWidth && 2 === colCount,
        [styles[`cols${colCount}`]]: 2 !== colCount,
        [wideStyle]: wide,
    });

    return (<Block className={containerClassNames} {...props}>
        {
            [col1, col2, col3].map((column, index) => {
                if (!column.content.length || index >= colCount) {
                    return '';
                }

                const columnClassNames = classNames({
                    [styles.column]: true,
                    [layout]: true,
                    [styles[`column${index + 1}`]]: !!styles[`column${index + 1}`],
                });


                return (
                    <SimpleLayout
                        key={index}
                        rowIndex={props.rowIndex ? `${props.rowIndex}-col${index}` : `col${index}`}
                        blocks={column.content}
                        layoutClasses={columnClassNames}/>
                );
            })
        }
    </Block>);
};

Columns.propTypes = {
    __typename: PropTypes.string.isRequired,
    col1: PropTypes.object.isRequired,
    col2: PropTypes.object.isRequired,
    col3: PropTypes.object,
    colCount: PropTypes.string.isRequired,
    colWidth: PropTypes.string.isRequired,
    invert: PropTypes.bool,
    centerVertically: PropTypes.bool,
    wide: PropTypes.bool,
    rowIndex: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
};

Columns.defaultProps = {
    col3: {},
    invert: false,
    centerVertically: false,
    wide: false,
    rowIndex: '',
};

export default Columns;
