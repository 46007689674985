/**
 * Our blog-header
 *
 * @author David Faber
 * @author Ahmed Al-Yasiri
 * @copyright (c) Peritus Webdesign GmbH
 **/
import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './blog-header.module.scss';
import {Link} from 'gatsby';
import Block from '../Block';

const BlogHeader = ({title, date, dateString, categories}) => {
    let withDate = true;

    if (categories.nodes && categories.nodes.length) {
        const mainCat = categories.nodes[0];
        withDate = mainCat.acf && mainCat.acf.withDate;
    }

    return (<Block>
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.subline}>
            {withDate && <time className={styles.date} dateTime={date}>{dateString}</time>}
            {categories.nodes && categories.nodes.map((cat, key) => (
                <Link key={key} className={styles.cat} to={cat.uri}>{cat.name}</Link>
            ))}
        </div>
    </Block>);
};

BlogHeader.propTypes = {
    title: PropTypes.string.isRequired,
    categories: PropTypes.shape({
        nodes: PropTypes.arrayOf(PropTypes.shape({
            uri: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })),
    }).isRequired,
    date: PropTypes.string.isRequired,
    dateString: PropTypes.string.isRequired,
};

export default BlogHeader;
