/**
 * Our blog-header
 *
 * @author David Faber
 * @author Ahmed Al-Yasiri
 * @copyright (c) Peritus Webdesign GmbH
 **/
// NPM
import React from 'react';
import {Link} from 'gatsby';
import PropTypes from 'prop-types';

// Peritus
import Hero from 'blocks/Hero';
import {ResponsiveImageProps} from '../ResponsiveImage';
import {CreateLocalLink, Translate} from 'utilities';

// Styles
import * as styles from './blog-hero.module.scss';
import {backLink} from '../../blocks/Hero/hero.module.scss';

// Assets
import ArrowIcon from 'images/arrow.inline.svg';

const BlogHero = ({thumbnailId, categories}) => (
    <Hero
        mediaType={'image'}
        image={thumbnailId}
        title={categories.nodes ? categories.nodes[0].name : 'Karriere News'}
        noH1={true}
    >
        <Link to={CreateLocalLink(categories.nodes[0].uri)} className={backLink}>
            <ArrowIcon className={styles.arrow}/>
            {Translate('blogHeroBack')}
        </Link>
    </Hero>
);

BlogHero.propTypes = {
    thumbnailId: PropTypes.shape({
        ...ResponsiveImageProps,
    }).isRequired,
    categories: PropTypes.shape({
        nodes: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            uri: PropTypes.string,
            acf: PropTypes.shape({
                withDate: PropTypes.bool,
            })
        }))
    })
};

export default BlogHero;
