/**
 * An Iframe component that supports Youtube and Vimeo
 *
 * @author David Faber
 * @author Ahmed Al-Yasiri
 * @copyright (c) Peritus Webdesign GmbH
 **/
import React from 'react';
import * as styles from './video-embed.module.scss';
import PropTypes from 'prop-types';

const VideoEmbed = ({url, width, height}) => {
    let title = 'Video Embed';
    if (url.includes('youtube')) {
        url = url.replace('https://www.youtube.com/watch?v=', 'https://www.youtube-nocookie.com/embed/')
            + '?rel=0&autoplay=1&modestbranding=1';
        title = 'Youtube Embed';
    } else if (url.includes('vimeo')) {
        url = url.replace('https://vimeo.com/', 'https://player.vimeo.com/video/') + '?autoplay=1';
        title = 'Vimeo Embed';
    }

    return (
        <iframe className={styles.iframe} width={width} height={height} allow={'fullscreen'} src={url} title={title}/>
    );
};

VideoEmbed.propTypes = {
    height: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
};

export default VideoEmbed;
